module.exports = {
	siteUrl: 'https://panda.org.au',
	buildsUrl: 'https://pandaorg.gtsb.io',
	previewUrl: 'https://preview-pandaorg.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/b03cca4d-11b8-4719-82fa-3dc99fe426fe',
	netlifySiteName: 'dot-gatsby',
	netlifyAppId: 'f8ff0074-d641-4e1f-a74a-62534e2fecf1',
	netlifyBuildHookId: '612d84bb368b5f3eb336ba9b',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'GTM-NJT87RP'
}